<script lang="ts" setup>
import { computed } from 'vue'
import { searchResults, searchTerm } from '@/services/search'
import { useStore } from '@nanostores/vue'

interface Image {
  id: string
  height: Number
  width: Number
}

interface Props {
  displayGroups: any[]
}

const props = defineProps<Props>()
const search = useStore(searchTerm)
const results = useStore(searchResults)
const isSearch = computed(() => {
  return !!search.value && !!results.value
})

const displayedGroups = props.displayGroups.filter(
  (displayGroup) => displayGroup.emoji && displayGroup.emoji !== '🏠',
)
</script>

<style>
  #nav-categories:not(.fixed) {
    max-width: 99% !important;
  }

  #nav-categories.fixed {
    width: calc(30rem - 4px);
  }
</style>

<template>
  <div
    v-if="!isSearch"
    id="nav-categories"
    class="grid grid-rows-2 grid-flow-col gap-x-2.5 gap-y-1 overflow-x-auto pl-4 md:pl-0 lg:pl-0 pr-9 py-2 mt-1.5 w-screen z-50 top-14 bg-white md:max-w-sm select-none hide-scrollbar"
  >
    <a
      v-for="displayGroup in displayedGroups"
      class="flex flex-col items-center justify-start w-items-4.5 md:w-24 h-20 gap-1 select-none"
      draggable="false"
      :href="`#${displayGroup.slug}`"
    >
      <span class="rounded-full bg-slate-100 px-2 py-1 text-center text-xl select-none">
        {{ displayGroup.emoji }}
      </span>
      <span
        class="text-center leading-tight text-slate-500 select-none line-clamp-3"
        style="font-size: 0.7rem"
      >
        {{ displayGroup.name.replace(displayGroup.emoji, '').trim() }}
      </span>
    </a>
  </div>
</template>
